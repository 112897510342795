import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../Shared'
import IrisImg from '../../components/Shared/IrisImg'
import AccentBorder from '../Shared/AccentBorder'
import PropTypes from 'prop-types'
import { calculatedView } from '../../utils/calculatedView'
import { chunk } from '../../utils/chunk'
import Upgrade from '../Products/Prospect/sections/Upgrade'
import './Headsets.global.scss'

const Heading = props => {
  const { view } = props
  const isDesktop = view === 'desktop'

  return (
    <div
      className={`content-container-${view}`}
      style={{
        paddingTop: isDesktop ? 100 : 40,
        paddingLeft: isDesktop ? 160 : 25,
        paddingBottom: 30,
      }}
    >
      <div
        style={{
          paddingBottom: 10,
        }}
      >
        <AccentBorder color={'purple'} />
      </div>
      <Link to={'/prospect'} style={{ textDecoration: 'none' }}>
        <p style={{ color: 'black', fontSize: 36 }}>
          Prospect is compatible with:
        </p>
      </Link>
    </div>
  )
}

const Logo = ({ view, style, logo }) => {
  return (
    <div className={`logo-${view}`} style={style}>
      <IrisImg className={`logo-${view}`} file={logo} alt={`logo`} />
    </div>
  )
}

const Headset = ({ view, style, headset }) => {
  return (
    <div className={`headset-${view}`} style={style}>
      <IrisImg className={`headset-${view}`} file={headset} alt={`headset`} />
    </div>
  )
}

const HeadsetName = ({ style, name }) => {
  return (
    <p className="gray" style={style}>
      {name}
    </p>
  )
}

const DesktopHeadsetDetail = props => {
  const {
    data: { logo, headset, name },
    view,
    isMiddleHeadset,
  } = props

  return (
    <div>
      <Logo
        view={view}
        logo={logo}
        style={{ paddingBottom: 40, paddingLeft: 50, paddingRight: 50 }}
      />
      <div
        className={`${
          isMiddleHeadset ? 'right-gray-border left-gray-border' : ''
        }`}
      >
        <Headset
          headset={headset}
          style={{ paddingBottom: 20, paddingLeft: 50, paddingRight: 50 }}
        />
      </div>
      <HeadsetName name={name} style={{ fontSize: 18 }} />
    </div>
  )
}

const MobileHeadsetDetail = props => {
  const {
    data: { logo, headset, name },
    view,
    isLastHeadset,
  } = props

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'center',
        }}
      >
        <Logo view={view} logo={logo} />
        <Headset view={view} headset={headset} style={{ paddingBottom: 15 }} />
        <HeadsetName name={name} style={{ fontSize: 18, paddingBottom: 35 }} />
      </div>
      {isLastHeadset ? (
        <div style={{ paddingBottom: 55 }} />
      ) : (
        <hr className="divider" />
      )}
    </div>
  )
}

const isQuest = name => name === 'Meta Quest 2'
const OculusQuestLinkWrapper = ({ children }) => {
  return (
    <Link to={'/oculus-quest'} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  )
}

const DesktopHeadsetRows = props => {
  const { headsetData, view } = props
  const isDesktop = view === 'desktop'

  if (!isDesktop) {
    return null
  }

  return chunk(Object.keys(headsetData), 3).map((headsetKeys, i) => {
    const headsetKeyValues = [...headsetKeys.map(hk => headsetData[hk])]

    return (
      <div
        key={`${view}-headset-row-${i}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          textAlign: 'center',
          paddingBottom: 120,
        }}
      >
        {headsetKeyValues.map((headset, idx) => {
          const isMiddleHeadset = idx === 1
          const headsetKey = headsetKeys[idx]

          if (isQuest(headset.name)) {
            return (
              <div key={`${view}-headset-${headsetKey}-detail`}>
                <OculusQuestLinkWrapper>
                  <DesktopHeadsetDetail
                    data={headset}
                    view={view}
                    isMiddleHeadset={isMiddleHeadset}
                  />
                </OculusQuestLinkWrapper>
              </div>
            )
          }

          return (
            <div key={`${view}-headset-${headsetKey}-detail`}>
              <DesktopHeadsetDetail
                data={headset}
                view={view}
                isMiddleHeadset={isMiddleHeadset}
              />
            </div>
          )
        })}
      </div>
    )
  })
}

const MobileHeadsetRows = props => {
  const { headsetData, view } = props
  const isDesktop = view === 'desktop'

  if (isDesktop) {
    return null
  }

  return Object.values(headsetData).map((headset, i) => {
    const isLastHeadset = i === 12

    if (isQuest(headset.name)) {
      return (
        <div key={`${view}-headset-detail-${i}`}>
          <OculusQuestLinkWrapper>
            <MobileHeadsetDetail
              data={headset}
              view={view}
              isLastHeadset={isLastHeadset}
            />
          </OculusQuestLinkWrapper>
        </div>
      )
    }
    return (
      <div key={`${view}-headset-detail-${i}`}>
        <MobileHeadsetDetail
          data={headset}
          view={view}
          isLastHeadset={isLastHeadset}
        />
      </div>
    )
  })
}

const HeadsetList = props => {
  const { headsetData, view } = props
  const isDesktop = view === 'desktop'

  return (
    <div
      className={`content-container-${view}`}
      style={{
        paddingLeft: isDesktop ? 110 : 25,
        paddingRight: isDesktop ? 110 : 25,
      }}
    >
      <DesktopHeadsetRows headsetData={headsetData} view={view} />
      <MobileHeadsetRows headsetData={headsetData} view={view} />
    </div>
  )
}

const FinalSection = ({ view, prospectLogo }) => {
  const isDesktop = view === 'desktop'
  return (
    <div
      style={{
        width: '100%',
        paddingTop: isDesktop ? 107 : 40,
        paddingBottom: isDesktop ? 107 : 40,
        backgroundColor: 'black',
      }}
    >
      <Upgrade
        logo={prospectLogo}
        mediaType={view}
        primaryCTA={'Ready to upgrade your model review workflow?'}
      />
    </div>
  )
}

const HeadsetOverview = props => {
  const { width, headsetData, prospectLogo } = props
  const view = calculatedView(width)
  const isDesktop = view === 'desktop'

  return (
    <div id="headsets-page">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Heading view={view} />
        <HeadsetList view={view} headsetData={headsetData} />
      </div>
    </div>
  )
}

HeadsetOverview.propTypes = {
  prospectLogo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    childImageSharp: PropTypes.shape({
      fixed: PropTypes.shape(),
      fluid: PropTypes.shape(),
    }),
  }),
  headsetData: PropTypes.objectOf(
    PropTypes.shape({
      logo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
      headset: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.shape(),
          fluid: PropTypes.shape(),
        }),
      }),
      name: PropTypes.string.isRequired,
    })
  ),
}

export default props => {
  return (
    <Layout>
      <HeadsetOverview {...props} />
    </Layout>
  )
}
